import React from 'react';
import Modal from 'react-modal';

const ModalManager = ({
  modalIsOpen,
  setModalIsOpen,
  loginModalOpen,
  setLoginModalOpen,
  registerModalOpen,
  setRegisterModalOpen,
  messageModalOpen,
  setMessageModalOpen,
  rectangleModalOpen,
  setRectangleModalOpen,
  selectedResponse,
  setSelectedResponse,
  inizioContent,
  handleLogin,
  handleRegister,
  handleSendMessage,
  loginData,
  setLoginData,
  registerData,
  setRegisterData,
  userMessage,
  setUserMessage,
  isLoading,
  message
}) => {
  return (
    <>
      {/* Modal for Circle (L'Inizio) */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="text-container">
          <h2>Inizio</h2>
          <div className="modal-text">
            {inizioContent}
          </div>
        </div>
        <button className="close-button" onClick={() => setModalIsOpen(false)}>
          Chiudi
        </button>
      </Modal>

      {/* Modal for Login */}
      <Modal
        isOpen={loginModalOpen}
        onRequestClose={() => setLoginModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Nome utente"
            value={loginData.username}
            onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={loginData.password}
            onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            required
          />
          <button type="submit">Entra</button>
        </form>
        <p>{message}</p>
      </Modal>

      {/* Modal for Register */}
      <Modal
        isOpen={registerModalOpen}
        onRequestClose={() => setRegisterModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Registrazione</h2>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="Nome utente"
            value={registerData.username}
            onChange={(e) => setRegisterData({ ...registerData, username: e.target.value })}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={registerData.email}
            onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={registerData.password}
            onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
            required
          />
          <button type="submit">Registrati</button>
        </form>
        <p>{message}</p>
      </Modal>

      {/* Modal for Sending Messages */}
      <Modal
        isOpen={messageModalOpen}
        onRequestClose={() => setMessageModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Scrivi un Messaggio</h2>
        <textarea
          className="textarea-message"
          placeholder="Scrivi il tuo messaggio qui..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
        />
        <button className="send-button" onClick={handleSendMessage}>
          INVIA
        </button>
        {isLoading && <div className="loading-indicator">AI is processing...</div>}
      </Modal>

      {/* Modal for displaying selected rectangle content */}
      <Modal
        isOpen={rectangleModalOpen}
        onRequestClose={() => setRectangleModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="text-container">
            <h2>Contenuto del Rettangolo</h2>
            {/* Display the full rectangle content */}
            <p>{selectedResponse}</p>
        </div>
        <button className="close-button" onClick={() => setRectangleModalOpen(false)}>
            Chiudi
        </button>
      </Modal>


    </>
  );
};

export default ModalManager;
