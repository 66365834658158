// localStorageHelper.js

// Save the username to localStorage
export const saveUsernameToLocalStorage = (username) => {
    localStorage.setItem('username', username);
  };
  
  // Get the username from localStorage
  export const getUsernameFromLocalStorage = () => {
    return localStorage.getItem('username');
  };
  
  // Remove the username from localStorage (on logout)
  export const removeUsernameFromLocalStorage = () => {
    localStorage.removeItem('username');
  };
  