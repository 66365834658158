// HomePage.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './HomePage.css';
import ModalManager from './ModalManager';
import FlowManager from './FlowManager'; // Importa il nuovo FlowManager
import { saveUsernameToLocalStorage, removeUsernameFromLocalStorage } from './localStorageHelper';
import { v4 as uuidv4 } from 'uuid';
import SplashScreen from './SplashScreen';

const HomePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inizioContent, setInizioContent] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [rectangleModalOpen, setRectangleModalOpen] = useState(false);
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [registerData, setRegisterData] = useState({ username: '', email: '', password: '' });
  const [userMessage, setUserMessage] = useState('');
  const [message, setMessage] = useState('');
  const [selectedResponse, setSelectedResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rectangles, setRectangles] = useState([]); // Stato per memorizzare i rettangoli
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [selectedRectangle, setSelectedRectangle] = useState(null);

  // Recupera lo username dal local storage quando la pagina viene caricata
  useEffect(() => {
    const storedUsername = localStorage.getItem('username') || 'Utente sconosciuto';
    setLoginData((prevData) => ({
      ...prevData,
      username: storedUsername
    }));
  }, []);

  // Gestione clic sul cerchio
  const handleCircleClick = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  // Gestione clic sul rettangolo per aprire la modale
  const handleRectangleClick = (rect) => {
    if (rect && rect.content) {
      setSelectedResponse(rect.content); // Imposta il contenuto della modale con i dettagli del rettangolo
      setSelectedRectangle(rect); // Imposta il rettangolo selezionato
      setRectangleModalOpen(true); // Apre la modale
    }
  };

  // Funzione di login
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/login`, loginData);
      setMessage(response.data.message);
      setIsLoggedIn(true);
      setLoginModalOpen(false);
      saveUsernameToLocalStorage(loginData.username);
    } catch (error) {
      console.error('Errore durante il login:', error);
      setMessage(error.response?.data?.message || 'Errore del server');
    }
  };

  // Funzione di logout
  const handleLogout = () => {
    removeUsernameFromLocalStorage();
    setIsLoggedIn(false);
    setMessage('Logout effettuato');
  };

  // Funzione di registrazione
  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/register`, registerData);
      setMessage(response.data.message);
      setRegisterModalOpen(false);
    } catch (error) {
      console.error('Errore durante la registrazione:', error);
      setMessage(error.response?.data?.message || 'Errore del server');
    }
  };

  // Funzione per gestire il click sul bottone "+" e generare un nuovo UUID
  const handleMessageClick = useCallback(() => {
    const storedUsername = localStorage.getItem('username') || 'Utente sconosciuto';

    // Genera un nuovo UUID ogni volta che clicchi
    const newRectangleId = `rect-${uuidv4()}`;

    const newRectangle = {
      user_id: 1,
      rectangle_id: newRectangleId,  // Assicurati che ogni rettangolo abbia un nuovo UUID
      content: 'In attesa di risposta...',
      filled: false,
      width: '200px',
      height: '100px',
      createdAt: new Date().toISOString(),
      username: storedUsername // Usa lo username salvato nel local storage
    };

    setRectangles((prevRectangles) => [...prevRectangles, newRectangle]); // Aggiorna i rettangoli esistenti
    setSelectedRectangle(newRectangle); // Imposta il nuovo rettangolo come selezionato
    setMessageModalOpen(true); // Apri la modale
  }, []);

  // Funzione per inviare il messaggio all'AI e aggiornare il rettangolo
  const handleSendMessage = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/send-message`, {
        input_text: userMessage,
        userId: 1,
      });

      const fullContent = response.data.aiResponse;

      // Aggiorna il contenuto del rettangolo selezionato
      setRectangles((prevRectangles) =>
        prevRectangles.map((rect) =>
          rect.rectangle_id === selectedRectangle?.rectangle_id
            ? { ...rect, content: fullContent, filled: true }
            : rect
        )
      );

      // Salva il nuovo stato del rettangolo nel backend
      await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/rectangles`, {
        user_id: 1,
        rectangle_id: selectedRectangle?.rectangle_id,
        content: fullContent,
        filled: true,
        width: selectedRectangle?.width,
        height: selectedRectangle?.height,
      });

      setConfirmationMessage('Messaggio inviato con successo!');  // Imposta il messaggio di conferma
      setIsLoading(false);
      setMessageModalOpen(false); // Chiudi la modale dopo l'invio

      // Fai svanire il messaggio dopo un certo tempo
      setTimeout(() => {
        setConfirmationMessage('');
      }, 3000); // Mostra il messaggio per 3 secondi
    } catch (error) {
      console.error('Errore durante l\'invio del messaggio:', error);
      setConfirmationMessage('Errore durante l\'invio del messaggio.');  // Imposta il messaggio di errore
      setIsLoading(false);
    }
  };

    // Recupero del contenuto iniziale e dei rettangoli
    useEffect(() => {
      fetch(process.env.PUBLIC_URL + '/inizio.txt')
        .then((response) => response.text())
        .then((text) => {
          const formattedText = text.replace(/\./g, '.\n');
          setInizioContent(formattedText);
        })
        .catch(error => console.error('Errore nel caricamento del file inizio:', error));
  
      axios.get(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/rectangles`)
        .then((response) => {
          setRectangles(response.data);
        })
        .catch(error => console.error('Errore nel recupero dei rettangoli:', error));
    }, []);
  
    return (
      <div className="home-page">
        <div className="sidebar">
          {isLoggedIn ? (
            <button className="sidebar-button" onClick={handleLogout}>Logout</button>
          ) : (
            <>
              <button className="sidebar-button" onClick={() => setLoginModalOpen(true)}>Login</button>
              <button className="sidebar-button" onClick={() => setRegisterModalOpen(true)}>Registrazione</button>
            </>
          )}
        </div>
  
        <div className="circle-node" onClick={handleCircleClick}>L'INIZIO</div>
  
        {isLoggedIn && (
          <button className="add-node-button" onClick={handleMessageClick}>+</button>
        )}
  
        {isLoading && <div className="loading-indicator">AI is processing...</div>}
  
        {/* FlowManager integrato */}
        <FlowManager rectangles={rectangles} handleRectangleClick={handleRectangleClick} />
  
        {/* ModalManager per la gestione delle modali */}
        <ModalManager
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          loginModalOpen={loginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          registerModalOpen={registerModalOpen}
          setRegisterModalOpen={setRegisterModalOpen}
          messageModalOpen={messageModalOpen}
          setMessageModalOpen={setMessageModalOpen}
          rectangleModalOpen={rectangleModalOpen}
          setRectangleModalOpen={setRectangleModalOpen}
          selectedResponse={selectedResponse} // Passa il contenuto selezionato alla modale
          setSelectedResponse={setSelectedResponse}
          inizioContent={inizioContent}
          handleLogin={handleLogin} // Gestione del login
          handleRegister={handleRegister} // Gestione della registrazione
          handleSendMessage={handleSendMessage} // Gestione dell'invio del messaggio
          loginData={loginData}
          setLoginData={setLoginData}
          registerData={registerData}
          setRegisterData={setRegisterData}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
          isLoading={isLoading}
          message={message}
        />
  
        {/* Messaggio di conferma che appare e svanisce lentamente */}
        {confirmationMessage && (
          <div className="confirmation-message">
            {confirmationMessage}
          </div>
        )}
      </div>
    );
  };
  
  export default HomePage;
  