import React, { useState } from 'react';
import './SplashScreen.css';

const SplashScreen = ({ onClose }) => {
  const [isMuted, setIsMuted] = useState(true); // State to track mute status
  const videoRef = React.useRef(null); // Use ref to control the video

  const toggleAudio = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted; // Toggle mute status
      setIsMuted(!isMuted); // Update the state
    }
  };

  return (
    <div className="splash-screen">
      <video ref={videoRef} className="splash-video" autoPlay muted>
        <source src="/videotrailer.webm" type="video/webm" />
        Your browser does not support the video.
      </video>
      
      <button className="audio-toggle-button" onClick={toggleAudio}>
        {isMuted ? '🔇' : '🔊'} {/* Change icon based on mute status */}
      </button>
      
      <button className="splash-close-button" onClick={onClose}>
        Entra
      </button>
    </div>
  );
};

export default SplashScreen;
