import React from 'react';
import './SecondSplashScreen.css'; // Stile CSS per il secondo splash screen

const SecondSplashScreen = ({ onClose }) => {
  return (
    <div className="splash-screen-2">
      <div className="splash-content-2">
        <h1 className="splash-title-2">Benvenuto su MicroWavePocalypse!</h1>
        <p className="splash-text-2">
          Questo sito è un'esperienza interattiva unica, una mappa mentale che si arricchisce grazie al contributo degli utenti.
          Al centro trovi il primo capitolo della storia, scritto da <strong>Yelena Vostrikov</strong>, una delle protagoniste
          dell'universo horror sci-fi Apollo Nephilim.
        </p>
        <h2 className="splash-subtitle-2">Come funziona?</h2>
        <ul className="splash-list-2">
          <li>
            Ogni utente può proporre la propria idea per continuare il racconto, che verrà poi rielaborata dall'intelligenza
            artificiale per mantenere la coerenza narrativa.
          </li>
          <li>
            Ogni contributo aggiungerà un nuovo nodo alla mappa, segnando il nome dell'utente, la data e l'ora della creazione.
          </li>
          <li>
            La storia evolve e cresce, diventando un romanzo collettivo.
          </li>
        </ul>
        <h2 className="splash-subtitle-2">Cosa puoi fare?</h2>
        <ul className="splash-list-2">
          <li>Leggere il capitolo iniziale e immergerti nell'avventura.</li>
          <li>Contribuire con le tue idee per sviluppare la trama.</li>
          <li>
            Osservare la mappa mentale che si espande, traccia delle vostre creazioni.
          </li>
        </ul>
        <p className="splash-text-2">
          Unisciti a noi e scopri come andrà a finire MicroWavePocalypse, un romanzo scritto dagli utenti, per gli utenti!
        </p>
        <p className="splash-footer-2">
          <a href="https://estro.games" target="_blank" rel="noopener noreferrer" className="splash-link-2">ESTRO.GAMES</a> | 
          <a href="https://apollonephilim.it" target="_blank" rel="noopener noreferrer" className="splash-link-2">APOLLO NEPHILIM</a>
        </p>
        <button className="close-button-2" onClick={onClose}>
          Entra nel sito
        </button>
      </div>
    </div>
  );
};

export default SecondSplashScreen;
