import React, { useState } from 'react';
import SplashScreen from './SplashScreen';
import HomePage from './HomePage';
import SecondSplashScreen from './SecondSplashScreen'; // Importa il secondo splash

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isSecondSplashVisible, setIsSecondSplashVisible] = useState(false);

  const handleSplashClose = () => {
    setIsSplashVisible(false);
    setIsSecondSplashVisible(true); // Mostra il secondo splash screen
  };

  const handleSecondSplashClose = () => {
    setIsSecondSplashVisible(false);
  };

  return (
    <div className="App">
      {isSplashVisible ? (
        <SplashScreen onClose={handleSplashClose} />
      ) : isSecondSplashVisible ? (
        <SecondSplashScreen onClose={handleSecondSplashClose} />
      ) : (
        <HomePage />
      )}
    </div>
  );
}

export default App;
