import React, { useState, useEffect } from 'react';
import ReactFlow, { MiniMap, Controls, Background, Handle, useNodesState, useEdgesState, addEdge } from 'reactflow';
import 'reactflow/dist/style.css';
import './FlowManager.css';

// Custom Node Definition
const CustomNode = ({ data }) => {
  return (
    <div className={`custom-node ${data.isFirstNode ? 'highlighted-node' : ''}`} onClick={data.onClick}>
      <span>{data.label}</span>
      <Handle type="target" position="top" />
      <Handle type="source" position="bottom" />
    </div>
  );
};

// Define nodeTypes
const nodeTypes = {
  custom: CustomNode,
};

const FlowManager = ({ rectangles, handleRectangleClick }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]); // Separate nodes state
  const [edges, setEdges, onEdgesChange] = useEdgesState([]); // Separate edges state

  // Update nodes and edges when rectangles data changes
  useEffect(() => {
    const newNodes = rectangles.map((rect, index) => {
      // Positioning logic: first node is above the center
      const position = index === 0 
        ? { x: 250, y: 50 } // Position the first node above the center
        : { x: 250, y: 150 + index * 150 }; // Other nodes follow a vertical layout

      return {
        id: rect.rectangle_id,
        type: 'custom',
        data: {
          label: `${new Date(rect.createdAt).toLocaleString('it-IT')} - ${rect.username || 'Utente sconosciuto'}`,
          onClick: () => handleRectangleClick(rect),
          isFirstNode: index === 0, // Flag the first node
        },
        position,
        draggable: index !== 0, // Make the first node inamovibile
      };
    });

    const newEdges = rectangles.slice(1).map((rect, index) => ({
      id: `e${rectangles[index].rectangle_id}-${rect.rectangle_id}`,
      source: rectangles[index].rectangle_id,
      target: rect.rectangle_id,
      animated: true,
      style: { stroke: 'green' },
    }));

    // Update both nodes and edges
    setNodes(newNodes);
    setEdges(newEdges);
  }, [rectangles, handleRectangleClick]);

  const onConnect = (params) => setEdges((eds) => addEdge(params, eds)); // Handle new edges

  return (
    <div className="react-flow-wrapper" style={{ height: '100%', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background color="#555" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default FlowManager;
